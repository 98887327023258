import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Header = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <header>
      <div className="container">
        <h1>Ankiデッキ作成屋</h1>
        <nav>
          <ul>
            <li><Link to="/">ホーム</Link></li>
            <li><Link to="/#materials">教材</Link></li>
            <li><Link to="/#ranking">人気ランキング</Link></li>
            <li><Link to="/#recommended">おすすめ</Link></li>
            <li>
              {user ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img 
                    src={user.photoURL} 
                    alt={user.displayName} 
                    style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '10px' }} 
                  />
                  <span style={{ marginRight: '10px' }}>{user.displayName}</span>
                  <button onClick={signOut}>ログアウト</button>
                </div>
              ) : (
                <button onClick={handleLogin}>ログイン</button>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
