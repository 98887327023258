import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">プライバシーポリシー</h1>
      <div className="space-y-4">
        <section>
          <h2 className="text-xl font-semibold mb-2">1. 個人情報の収集</h2>
          <p>当サイトでは、お問い合わせやサービス利用の際に、お名前、メールアドレス等の個人情報をご提供いただく場合があります。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">2. 個人情報の利用目的</h2>
          <p>収集した個人情報は、サービスの提供、お問い合わせへの回答、ユーザーサポート、サービス改善のために利用します。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">3. 個人情報の第三者提供</h2>
          <p>法令に基づく場合を除き、収集した個人情報を第三者に提供することはありません。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">4. セキュリティ</h2>
          <p>個人情報の漏洩、紛失、改ざん等を防ぐため、適切なセキュリティ対策を実施しています。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">5. プライバシーポリシーの変更</h2>
          <p>本ポリシーの内容は、法令変更等の理由により、予告なく変更される場合があります。</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;