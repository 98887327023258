import React from 'react';
import { useCart } from './CartContext';
import { useAuth } from './AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import Header from './Header';

const Cart = () => {
  const { cart, removeFromCart } = useCart();
  const { user, signIn } = useAuth();
  const navigate = useNavigate();

  const handleCheckout = () => {
    if (user) {
      navigate('/checkout');
    } else {
      signIn(); // ログインしていない場合はログインを要求
    }
  };

  const calculateTotal = () => {
    return cart.reduce((total, item) => total + parseFloat(item.price), 0).toFixed(2);
  };

  return (
    <div>
      <Header />
      <div className="container">
        <h2>カート</h2>
        {cart.length === 0 ? (
          <p>カートは空です。</p>
        ) : (
          <div>
            <ul>
              {cart.map((item) => (
                <li key={item.id}>
                  <img src={item.imgSrc} alt={item.title} style={{ width: '100px' }} />
                  <h3>{item.title}</h3>
                  <p>{item.price}</p>
                  <button onClick={() => removeFromCart(item.id)}>削除</button>
                </li>
              ))}
            </ul>
            <p>合計: ¥{calculateTotal()}</p>
          </div>
        )}
        {cart.length > 0 && (
          <button onClick={handleCheckout}>購入画面へ進む</button>
        )}
        <Link to="/">ホームに戻る</Link>
      </div>
    </div>
  );
};

export default Cart;