import React, { useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CartProvider } from './CartContext';
import { AuthProvider, useAuth } from './AuthContext';
import Home from './Home';
import Product from './Product';
import Cart from './Cart';
import Checkout from './Checkout';
import Login from './components/Login';
import SuccessPage from './components/SuccessPage';
import CancelPage from './components/CancelPage';
import SpecificCommercialTransactionLaw from './components/SpecificCommercialTransactionLaw';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import ContactForm from './components/ContactForm';

const ProtectedRoute = React.memo(({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) return <div>Loading...</div>;
  return user ? children : <Navigate to="/login" />;
});

function AppRoutes() {
  const { user } = useAuth();
  
  if (process.env.NODE_ENV === 'development') {
    console.log("AppRoutes rendered, user:", user);
  }

  const protectedCheckout = useCallback(
    () => (
      <ProtectedRoute>
        <Checkout />
      </ProtectedRoute>
    ),
    []
  );

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Home />} />
      <Route path="/product/:id" element={<Product />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={protectedCheckout} />
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/cancel" element={<CancelPage />} />
      <Route path="/specific-commercial-transaction-law" element={<SpecificCommercialTransactionLaw />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/contact" element={<ContactForm />} />
    </Routes>
  );
}

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <Router>
          <div className="App">
            <AppRoutes />
          </div>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;