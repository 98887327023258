// src/components/SpecificCommercialTransactionLaw.js
import React from 'react';

const SpecificCommercialTransactionLaw = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">特定商取引法に基づく表記</h1>
      <div className="space-y-6">
        <section>
          <h2 className="text-xl font-semibold mb-2">販売業者</h2>
          <p>Anki Card Creator（アンキ カード クリエイター）</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">所在地</h2>
          <p>東京都渋谷区</p>
          <p>※お問い合わせの際の詳細な住所は、お問い合わせ後にお知らせいたします。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">連絡先</h2>
          <p>メールアドレス: info@ankisakusei.com</p>
          <p>※お電話でのお問い合わせは受け付けておりません。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">商品の販売価格</h2>
          <p>各商品ページに記載</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">商品以外の必要料金</h2>
          <p>消費税</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">引渡し時期</h2>
          <p>お支払い確認後、即時にダウンロードリンクをメールにてお送りいたします。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">お支払い方法</h2>
          <p>クレジットカード決済（Stripe決済）</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">返品・交換・キャンセル等</h2>
          <p>デジタルコンテンツの性質上、購入後の返品・交換・キャンセルはお受けできません。</p>
        </section>
      </div>
    </div>
  );
};

export default SpecificCommercialTransactionLaw;