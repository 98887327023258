import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import Header from './Header';

const products = [
  { id: 1, imgSrc: '/images/asahi_ankoku_phrase.jpg', title: 'TOEIC L&R TEST 超上級単語特急 暗黒のフレーズ', price: '¥1,980' },
  { id: 2, imgSrc: '/images/ask_deru1000.jpg', title: 'TOEIC L&R TEST 文法問題 でる1000問', price: '¥3,980' },
  { id: 3, imgSrc: '/images/duo.jpg', title: 'DUO 3.0', price: '¥1,980' },
  { id: 4, imgSrc: '/images/oubunsha_990_goi.jpg', title: 'TOEIC L&R TEST 990点攻略 文法・語彙問題 1000', price: '¥3,980' },
  { id: 5, imgSrc: '/images/oubunsha_ielts.png', title: '実践 IELTS 英単語 3500', price: '¥4,980' },
  { id: 6, imgSrc: '/images/oubunsha_passtan_1q_5.jpg', title: '英検 1級 でる順パス単', price: '¥2,980' },
  { id: 7, imgSrc: '/images/tokyu_ginhure_new.jpg', title: 'TOEIC L&R TEST 出る単特急 銀のフレーズ', price: '¥1,980' },
  { id: 8, imgSrc: '/images/tokyu_kin_sentence.jpg', title: 'TOEIC L&R TEST 出る単特急 金のセンテンス', price: '¥1,980' },
  { id: 9, imgSrc: '/images/tokyu_kinhure_new.jpg', title: 'TOEIC L&R TEST 出る単特急 金のフレーズ', price: '¥1,980' },
  { id: 10, imgSrc: '/images/tokyu_kurohure_new.jpg', title: 'TOEIC L&R TEST 上級単語特急 黒のフレーズ', price: '¥1,980' },
  { id: 11, imgSrc: '/images/D1BAND_1080x.webp', title: 'Distinction 1', price: '¥3,980' },
  { id: 12, imgSrc: '/images/D2BAND_530x@2x.webp', title: 'Distinction 2', price: '¥3,980' },
  //{ id: 13, imgSrc: '/images/D2BAND_530x@2x.webp', title: 'TEST', price: '¥100' },
  { id: 14, imgSrc: '/images/detatan1kyuu.jpg', title: 'でた単 1級（6819語＋例文）', price: '¥5980'},
  { id: 15, imgSrc: '/images/TOEFL3800.webp', title: 'TOEFL 3800', price: '¥3480'},
  { id: 16, imgSrc: '/images/passtanzyun1.jpg', title: 'パス単 準1級 4訂版', price: '¥2980' },
  { id: 17, imgSrc: '/images/システム英単語.jpg', title: 'システム英単語', price: '¥2980' },
  { id: 18, imgSrc: '/images/passtan4tei.webp', title: 'パス単 1級 4訂版', price: '¥3480' },
];

const Home = () => (
  <div>
    <Header />
    <main>
      <section id="materials" className="materials">
        <div className="container">
          <h2>教材</h2>
          <input type="text" placeholder="書籍検索 (タイトル、著者、出版社)" />
        </div>
      </section>
      <section id="ranking" className="ranking">
        <div className="container">
          <h2>人気ランキング</h2>
          <DeckGrid />
        </div>
      </section>
      <section id="recommended" className="recommended">
        <div className="container">
          <h2>あなたにおすすめ</h2>
          <DeckGrid />
        </div>
      </section>
    </main>
    <Footer />
  </div>
);

const DeckGrid = () => (
  <div className="deck-grid">
    {products.map(product => (
      <DeckCard key={product.id} product={product} />
    ))}
  </div>
);

const DeckCard = ({ product }) => (
  <div className="deck-card">
    <Link to={`/product/${product.id}`}>
      <img src={product.imgSrc} alt={product.title} />
      <h3>{product.title}</h3>
      <p>{product.price}</p>
    </Link>
  </div>
);

const Footer = () => (
  <footer>
    <div className="container">
      <p>&copy; 2024 Ankiデッキ作成屋. All rights reserved.</p>
      <ul>
        <li><Link to="/privacy-policy">プライバシーポリシー</Link></li>
        <li><Link to="/terms-of-service">利用規約</Link></li>
        <li><Link to="/contact">お問い合わせ</Link></li>
        <li><Link to="/specific-commercial-transaction-law">特定商取引法に基づく表記</Link></li>
      </ul>
    </div>
  </footer>
);

export default Home;