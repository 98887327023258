import React from 'react';
import { useParams } from 'react-router-dom';
import Header from './Header';
import { createCheckoutSession, stripePromise } from './stripe';

const products = [
  { id: 1, imgSrc: '/images/asahi_ankoku_phrase.jpg', title: 'TOEIC L&R TEST 超上級単語特急 暗黒のフレーズ', price: 1980, productId: 'prod_QbUQvaXWX49NWw' },
  { id: 2, imgSrc: '/images/ask_deru1000.jpg', title: 'TOEIC L&R TEST 文法問題 でる1000問', price: 3980, productId: 'prod_QbUSMpx0NDEVIn' },
  { id: 3, imgSrc: '/images/duo.jpg', title: 'DUO 3.0', price: 1980, productId: 'prod_QbUSv05cNXkjty' },
  { id: 4, imgSrc: '/images/oubunsha_990_goi.jpg', title: 'TOEIC L&R TEST 990点攻略 文法・語彙問題 1000', price: 3980, productId: 'prod_QbUShPHisY1tiQ' },
  { id: 5, imgSrc: '/images/oubunsha_ielts.png', title: '実践 IELTS 英単語 3500', price: 4980, productId: 'prod_QbUSM4qQ9PZcpD' },
  { id: 6, imgSrc: '/images/oubunsha_passtan_1q_5.jpg', title: '英検 1級 でる順パス単 5訂版', price: 2980, productId: 'prod_QbUSR28QF5DmrG' },
  { id: 7, imgSrc: '/images/tokyu_ginhure_new.jpg', title: 'TOEIC L&R TEST 出る単特急 銀のフレーズ', price: 1980, productId: 'prod_QbUS9JRnqBbpal' },
  { id: 8, imgSrc: '/images/tokyu_kin_sentence.jpg', title: 'TOEIC L&R TEST 出る単特急 金のセンテンス', price: 1980, productId: 'prod_QbUScYiBTjYyXb' },
  { id: 9, imgSrc: '/images/tokyu_kinhure_new.jpg', title: 'TOEIC L&R TEST 出る単特急 金のフレーズ', price: 1980, productId: 'prod_QbUSUV3L6ihDss' },
  { id: 10, imgSrc: '/images/tokyu_kurohure_new.jpg', title: 'TOEIC L&R TEST 上級単語特急 黒のフレーズ', price: 1980, productId: 'prod_QbUSruHfc4BdlP' },
  { id: 11, imgSrc: '/images/D1BAND_1080x.webp', title: 'Distinction 1', price: 3980, productId: 'prod_QbUSCAVTUYcSGP' },
  { id: 12, imgSrc: '/images/D2BAND_530x@2x.webp', title: 'Distinction 2', price: 3980, productId: 'prod_QbUSdyJJxSNna3' },
  //{ id: 13, imgSrc: '/images/D2BAND_530x@2x.webp', title: 'TEST', price: 100, productId: 'prod_QbpmCGTo2oSLia' },
  { id: 14, imgSrc: '/images/detatan1kyuu.jpg', title: 'でた単 1級（6819語＋例文）', price: 5980, productId: 'prod_Qw6M8cPqV7Xmt8' },
  { id: 15, imgSrc: '/images/TOEFL3800.webp', title: 'TOEFL 3800', price: 3480, productId: 'prod_Qw6K2FcBQMr8wR' },
  { id: 16, imgSrc: '/images/passtanzyun1.jpg', title: 'パス単 準1級 4訂版', price: 2980, productId: 'prod_Qw6KwtoXARXKIJ' },
  { id: 17, imgSrc: '/images/システム英単語.jpg', title: 'システム英単語', price: 2980, productId: 'prod_Qw6JCPTQ17VrwK' },
  { id: 18, imgSrc: '/images/passtan4tei.webp', title: 'パス単 1級 4訂版', price: 3480, productId: 'prod_Qw6IgzuWA6hRJq' },
];

const Product = () => {
  const { id } = useParams();
  const product = products.find(product => product.id.toString() === id);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleBuyNow = async () => {
    try {
      const stripe = await stripePromise;
      const session = await createCheckoutSession(product.productId, product.price);
      
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id
      });

      if (error) {
        console.error('Error redirecting to checkout:', error);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  return (
    <>
      <Header />
      <div className="product-page">
        <div className="product-page__image-container">
          <img src={product.imgSrc} alt={product.title} className="product-page__image" />
        </div>
        <div className="product-page__info">
          <h2 className="product-page__title">{product.title}</h2>
          <p className="product-page__price">¥{product.price}</p>
          <button className="product-page__buy-button" onClick={handleBuyNow}>
            購入
          </button>
        </div>
      </div>
    </>
  );
};

export default Product;