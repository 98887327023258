import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CancelPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // 5秒後にカートページにリダイレクト
    const timer = setTimeout(() => {
      navigate('/cart');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="cancel-page">
      <h1>購入がキャンセルされました</h1>
      <p>カートに戻って再度お試しください。</p>
      <p>5秒後にカートページに戻ります...</p>
    </div>
  );
};

export default CancelPage;