import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">利用規約</h1>
      <div className="space-y-4">
        <section>
          <h2 className="text-xl font-semibold mb-2">1. サービスの利用</h2>
          <p>本サービスを利用することで、この利用規約に同意したものとみなします。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">2. アカウント</h2>
          <p>ユーザーは自己の責任においてアカウント情報を管理するものとします。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">3. 禁止事項</h2>
          <p>本サービスの不正利用、違法行為、他者への迷惑行為は禁止します。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">4. 知的財産権</h2>
          <p>本サービス上のコンテンツの著作権は当社または正当な権利者に帰属します。</p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-2">5. 免責事項</h2>
          <p>本サービスの利用により生じた損害について、当社は一切の責任を負いません。</p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;