import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header';

const SuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 300000); // 5分後にホームページにリダイレクト

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <>
      <Header />
      <div className="success-page">
        <h1>ご購入ありがとうございます！</h1>
        <p>
          ご購入いただき、誠にありがとうございます。<br />
          購入された商品へのアクセスリンクは、ご登録のメールアドレスに送信されました。
        </p>
        <p>5分後にホームページに戻ります。</p>
      </div>
    </>
  );
};

export default SuccessPage;
