import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from './firebase';
import { signInWithPopup, GoogleAuthProvider, signOut as firebaseSignOut } from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log("Auth state changed", user);
      setUser(user);
      setLoading(false);
    }, (error) => {
      console.error("Auth state change error:", error);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const signIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      console.log("Attempting to sign in...");
      const result = await signInWithPopup(auth, provider);
      console.log("Sign in successful", result.user);
      setUser(result.user);
    } catch (error) {
      console.error("ログインエラー:", error);
      if (error.code) console.error("Error code:", error.code);
      if (error.message) console.error("Error message:", error.message);
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      console.log("Sign out successful");
      setUser(null);
    } catch (error) {
      console.error("ログアウトエラー:", error);
    }
  };

  const value = {
    user,
    loading,
    signIn,
    signOut
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
