import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // パスが正しいことを確認してください

const Login = () => {
  const { user, signIn, loading } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user && !loading) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  const handleSignIn = async () => {
    try {
      setError(null);
      await signIn();
      // signInは自動的にユーザー状態を更新するので、
      // ここでナビゲートする必要はありません
    } catch (error) {
      console.error("ログインエラー:", error);
      setError("ログインに失敗しました。もう一度お試しください。");
    }
  };

  console.log("Login component rendered, user:", user, "loading:", loading); // デバッグ用

  if (loading) return <div>Loading...</div>;
  if (user) return null; // ユーザーがログイン済みの場合は何も表示しない

  return (
    <div>
      <h2>ログイン</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleSignIn}>Google でログイン</button>
    </div>
  );
};

export default Login;
